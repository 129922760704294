import { Button, Divider, FormControl, Grid, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import Axios from "../../../api/Axios";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useGetProvidentSettings from "../../../hooks/useGetProvidentSettings";

function SalaryInfoTabNew() {
    const [salaryDetails, setSalaryDetails] = useState({
        additions: 0,
        deductions: 0,
        total: 0,
        bonusPercentage: 0,
    });
    const [salaryType, setSalaryType] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, setTabValue, handleShowTabCondition } = useEmployeeContext();

    const changeHandler = (value, index) => {
        const salaries = [...salaryType];
        if (value.length > 0) {
            salaries[index].amount = parseInt(value);
            setSalaryType(salaries);
        } else {
            salaries[index].amount = 0;
        }
        setSalaryType(salaries);
    };

    const validationSchema = Yup.object().shape({});
    //react-hook-form
    const { handleSubmit, reset, control, setValue, watch } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    const watchedFields = watch();

    // Total
    const [totalAdditions, setTotalAdditions] = useState(0);
    const [totalDeductions, setTotalDeductions] = useState(0);
    const [gradTotal, setGradTotal] = useState(0);
    const [bonusPercentage, setBonusPercentage] = useState(0);

    const [providentFund, setProvidentFund] = useState(0);

    const { data: providentFundValues } = useGetProvidentSettings();

    //Submit form
    const handleSubmitForm = async (data) => {
        console.log("handleSubmitForm - data:", data);

        const submitData = {
            ...data,
            26: providentFund,
            1: bonusPercentage,
            employee_id: id ? id : basicEmployeeInfo?.id,
        };

        try {
            const res = await Axios.post(`/employee/salary`, submitData, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log("employee res:", res);
            if (res?.data?.status === "success") {
                toast.success("Salary info data saved");
                handleShowTabCondition("leaves");
                // setTabValue((prev) => prev + 1);
                setErrorMessage(null);
            } else if (res.data.success === "200") {
                toast.info("You don't have salary info create  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log("handleBasicSubmit - error:", error);
            toast.warn("Something went wrong");
            setErrorMessage(error?.response?.data?.errors);
        }
    };

    //get all salary type
    const getAllSalaryType = async () => {
        try {
            const response = await Axios.get(`type-of-salary/all`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });

            setSalaryType(response.data.response_data);
            setShouldFetch((prev) => !prev);
        } catch (error) {
            console.log(error.response.status);
        }
    };

    //get previous data
    const getPreviousData = async () => {
        try {
            const res = await Axios.get(`employee/salary/show/${id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });

            if (res.data.response_data && res.data.response_data.length > 0) {
                let totalAdditon = 0;
                let totalDeduction = 0;
                let bonusAmountPercent = 0;

                const tempSalaryType = [...salaryType];
                res.data.response_data.forEach((salary) => {
                    setValue(`${salary.salary_type_id}`, salary.value || "");

                    // Total Addition or Deduction
                    if (salary?.salary_type?.type === 0 && salary?.salary_type?.name !== "Bonus") {
                        console.log("totalAdditon: ", salary?.value);
                        if (Number(salary?.value) > 0) {
                            totalAdditon += Number(salary?.value);
                        }
                    }
                    if (salary?.salary_type?.type === 1 && salary?.salary_type?.name !== "Bonus") {
                        if (Number(salary?.value) > 0) {
                            totalDeduction += Number(salary?.value);
                        }
                    }

                    if (salary?.salary_type?.type === 0 && salary?.salary_type?.name === "Bonus") {
                        if (salary?.value) {
                            bonusAmountPercent = Number(salary?.value);
                        }
                    }
                });
                setSalaryType(tempSalaryType);

                // Setting Totals Additions/Deductions
                setTotalAdditions(totalAdditon);
                setTotalDeductions(totalDeduction);
                setGradTotal(totalAdditon - totalDeduction);
                setBonusPercentage(bonusAmountPercent);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //Call  salary type
    useEffect(() => {
        getAllSalaryType();
    }, []);

    useEffect(() => {
        reset();
    }, []);

    //Call function get previous data
    useEffect(() => {
        if (id) {
            getPreviousData();
        }
    }, [id, shouldFetch]);

    const [bonusAmount, setBonusAmount] = useState(0);

    useEffect(() => {
        const pf = parseInt(watchedFields["8"]) * (providentFundValues?.reponse_data?.employee_contribution / 100);
        setProvidentFund(pf.toFixed(2));
        const additions = salaryType
            ?.filter((item) => item.type === 0 && item.id !== 1)
            ?.reduce((acc, salary) => acc + (Number(watchedFields[salary.id]) || 0), 0);

        const deductions = salaryType
            ?.filter((item) => item.type === 1 && `${item?.id}` !== "26")
            ?.reduce((acc, salary) => acc + (Number(watchedFields[salary.id]) || 0), 0);

        setTotalAdditions(additions);
        setTotalDeductions(deductions + pf);
        const bonus = additions * (bonusPercentage / 100);
        setBonusAmount(bonus);
        setGradTotal(additions - (deductions + pf) + bonus);
    }, [watchedFields, salaryType]);

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12} spacing={1.5}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Additions
                    </Typography>
                    {salaryType
                        ?.filter((item) => item.type === 0 && item.id !== 1)
                        ?.map((salary, index) => (
                            <Controller
                                key={index}
                                render={({ field }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                        <OutlinedInput
                                            sx={{ marginBottom: 1.5 }}
                                            {...field}
                                            type="number"
                                            placeholder={`Enter ${salary.name}`}
                                            inputProps={{ min: 0 }}
                                        />
                                    </FormControl>
                                )}
                                name={`${salary.id}`}
                                control={control}
                                defaultValue={0}
                            />
                        ))}
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Deductions
                    </Typography>
                    {salaryType
                        ?.filter((item) => item.type === 1 && `${item?.id}` !== `26`)
                        .map((salary, index) => (
                            <Controller
                                key={index + 22}
                                render={({ field }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                        <OutlinedInput sx={{ marginBottom: 1.5 }} {...field} type="number" placeholder={`Enter ${salary.name}`} />
                                    </FormControl>
                                )}
                                name={`${salary.id}`}
                                control={control}
                                defaultValue={0}
                            />
                        ))}

                    <FormControl fullWidth variant="outlined" size={"small"}>
                        <Typography sx={{ textTransform: "capitalize", color: "gray" }}>
                            Provident Fund ({providentFundValues?.reponse_data?.employee_contribution}% of Basic )
                        </Typography>
                        <OutlinedInput sx={{ marginBottom: 1.5 }} type="number" value={providentFund} disabled />
                    </FormControl>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <FormControl variant="outlined" size={"small"}>
                        <Typography sx={{ textTransform: "capitalize" }}>Bonus (%)</Typography>
                        <OutlinedInput
                            sx={{ marginBottom: 1.5 }}
                            type="number"
                            value={bonusPercentage}
                            onChange={(event) => {
                                if (event.target.value) {
                                    setBonusPercentage(event.target.value);
                                } else {
                                    setBonusPercentage(0);
                                }
                            }}
                            placeholder={`Enter Bonus...`}
                            inputProps={{ min: 0 }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={3} sm={3} xs={12}>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Total Additions: {parseFloat(totalAdditions).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Total Deductions: {parseFloat(totalDeductions).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Bonus: {parseFloat(bonusAmount.toFixed(2))}
                    </Typography>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Grand Total: {parseFloat(gradTotal.toFixed(2))}
                    </Typography>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Divider sx={{ mb: 2 }} />
                </Grid>
            </Grid>

            <>
                <Typography variant="h6" mt={2}>
                    {" "}
                    Info{" "}
                </Typography>
                <Divider sx={{ mb: 1.5 }} />
                <Grid container spacing={1.5}>
                    {salaryType
                        ?.filter((item) => item.type === 2)
                        .map((salary, index) => (
                            <Controller
                                key={index + 33}
                                render={({ field }) => (
                                    <Grid item md={4} sm={6} xs={12} key={index + 22}>
                                        <FormControl fullWidth variant="outlined" size={"small"}>
                                            {salary.name === "Tax Applicable?" ? (
                                                <>
                                                    <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                                    <Select {...field} placeholder={`Select ${salary.name}`}>
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>
                                                    </Select>
                                                </>
                                            ) : salary.name === "Circle" || salary.name === "Zone" ? (
                                                <>
                                                    <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                                    <OutlinedInput {...field} placeholder={`Enter ${salary.name}`} />
                                                </>
                                            ) : salary.name === "Tax Return Date" ? (
                                                <>
                                                    <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                                    <OutlinedInput {...field} type="date" placeholder={`Enter ${salary.name}`} />
                                                </>
                                            ) : (
                                                <>
                                                    <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                                    <OutlinedInput
                                                        {...field}
                                                        placeholder={`Enter ${salary.name}`}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                    />
                                                </>
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}
                                name={`${salary.id}`}
                                control={control}
                                defaultValue={""}
                            />
                        ))}
                </Grid>
            </>

            <Divider sx={{ mt: 2 }} />
            <ErrorMessageShow handleError={errorMessage} />
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button variant="contained" type={"submit"} onClick={() => console.log(salaryType)}>
                    Save
                </Button>
            </Stack>
        </form>
    );
}

export default SalaryInfoTabNew;
